import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
//bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
// @splidejs/vue-splide 图片轮播插件
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
// 动画效果
import 'animate.css/animate.min.css';

const app = createApp(App);
app.use(VueSplide);
app.use(router).mount('#app');
