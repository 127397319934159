import { onMounted, reactive, ref, watch } from 'vue';
import WOW from 'wow.js';
export default {
  name: 'WeddingIndex',

  setup() {
    const dd = ref();
    const hh = ref();
    const mm = ref();
    const ss = ref();

    function test() {
      // 当前时间
      var nowTime = new Date().getTime(); // 结束时间

      var endTime = new Date('2022/10/17'); // 相差的时间

      var t = endTime.getTime() - nowTime;
      dd.value = Math.floor(t / 1000 / 60 / 60 / 24);
      hh.value = Math.floor(t / 1000 / 60 / 60 % 24);
      mm.value = Math.floor(t / 1000 / 60 % 60);
      ss.value = Math.floor(t / 1000 % 60);
    } // 判断当前是否为移动端


    function isMobile() {
      var userAgent = window.navigator.userAgent;

      if (userAgent.indexOf('Mobile') >= 0) {
        return true;
      }
    }

    const windowWidth = ref(null); // 监控当前视窗大小

    function windowResize() {
      windowWidth.value = document.body.clientWidth;
    } // 数字小于10前面就加0
    // function addZero(num) {
    //   return num < 10 ? '0' + num : num;
    // }
    // 初始化轮播


    const options = reactive({
      rewind: true,
      width: '100%',
      type: 'loop',
      perMove: 1,
      gap: '0.5rem',
      autoplay: true,
      interval: 3000,
      perPage: 3
    }); // 图片预览主纱

    const url = require('@/assets/4T0A2162.jpg');

    const srcList = [require('@/assets/4T0A2061.jpg'), require('@/assets/4T0A2067.jpg'), require('@/assets/4T0A2072.jpg'), require('@/assets/4T0A2077.jpg'), require('@/assets/4T0A2081.jpg'), require('@/assets/4T0A2102.jpg'), require('@/assets/4T0A2103.jpg'), require('@/assets/4T0A2116.jpg'), require('@/assets/4T0A2122.jpg'), require('@/assets/4T0A2124.jpg'), require('@/assets/4T0A2130.jpg'), require('@/assets/4T0A2148.jpg'), require('@/assets/4T0A2149.jpg'), require('@/assets/4T0A2151.jpg'), require('@/assets/4T0A2155.jpg'), require('@/assets/4T0A2157.jpg'), require('@/assets/4T0A2162.jpg'), require('@/assets/4T0A2165.jpg'), require('@/assets/4T0A2166.jpg'), require('@/assets/4T0A2181.jpg')]; // 图片预览光影

    const guangying = require('@/assets/4T0A2263+.jpg');

    const guangyingList = [require('@/assets/4T0A2263+.jpg'), require('@/assets/4T0A2256.jpg'), require('@/assets/4T0A2262.jpg'), require('@/assets/4T0A2263+.jpg'), require('@/assets/4T0A2268.jpg'), require('@/assets/4T0A2270.jpg'), require('@/assets/4T0A2281.jpg'), require('@/assets/4T0A2282.jpg'), require('@/assets/4T0A2288.jpg'), require('@/assets/4T0A2289.jpg'), require('@/assets/4T0A2295.jpg'), require('@/assets/4T0A2297.jpg'), require('@/assets/4T0A2300.jpg'), require('@/assets/4T0A2310.jpg'), require('@/assets/4T0A2311.jpg'), require('@/assets/4T0A2313.jpg')]; //图片预览外景

    const wai = require('@/assets/4T0A2483.jpg');

    const waiList = [require('@/assets/4T0A2483.jpg'), require('@/assets/4T0A2353+.jpg'), require('@/assets/4T0A2359.jpg'), require('@/assets/4T0A2413.jpg'), require('@/assets/4T0A2416.jpg'), require('@/assets/4T0A2424.jpg'), require('@/assets/4T0A2462.jpg'), require('@/assets/4T0A2479.jpg'), require('@/assets/4T0A2498.jpg'), require('@/assets/4T0A2533.jpg'), require('@/assets/4T0A2541.jpg'), require('@/assets/4T0A2545.jpg'), require('@/assets/4T0A2546.jpg')]; //加载组件

    onMounted(() => {
      //倒计时
      setInterval(() => {
        test();
      }, 1000); //判断是否为移动端

      const Mobile = isMobile();

      if (Mobile) {
        options.perPage = 1;
      } // 视窗大小


      window.onresize = windowResize; //动画效果

      new WOW({
        mobile: true,
        // 是否在移动端奏效
        scrollContainer: null,
        // optional scroll container selector, otherwise use window,
        resetAnimation: true // reset animation on end (default is true)

      }).init();
    });
    watch(windowWidth, now => {
      if (now > 768) {
        options.perPage = 4;
      } else {
        options.perPage = 1;
      }
    });
    return {
      dd,
      hh,
      mm,
      ss,
      options,
      guangying,
      guangyingList,
      wai,
      waiList,
      url,
      srcList
    };
  }

};